import api from '@/services/Api'

const BUCKETS_ROUTE = (brandId) => `/brands/${brandId}/rss-buckets`
const SINGLE_BUCKET_ROUTE = (brandId, bucketId) => BUCKETS_ROUTE(brandId) + '/' + bucketId
export const BUCKET_ITEMS_ROUTE = (brandId, bucketId) => SINGLE_BUCKET_ROUTE(brandId, bucketId) + '/items'

export const RSSBucketApiService = {
  fetchAll (brandId, params) {
    return api.getData(BUCKETS_ROUTE(brandId), params)
  },
  create (brandId, payload, params) {
    return api.postData(BUCKETS_ROUTE(brandId), payload, params)
  },
  fetchBucket (brandId, bucketId, params) {
    return api.getData(SINGLE_BUCKET_ROUTE(brandId, bucketId), params)
  },
  updateBucket (brandId, bucketId, payload, params) {
    return api.putData(SINGLE_BUCKET_ROUTE(brandId, bucketId), payload, params)
  },
  deleteBucket (brandId, bucketId, params) {
    return api.delete(SINGLE_BUCKET_ROUTE(brandId, bucketId), params)
  },
  fetchBucketItems (brandId, bucketId, params) {
    return api.getData(BUCKET_ITEMS_ROUTE(brandId, bucketId), params)
  },
  addItemsToBucket (brandId, bucketId, payload, params) {
    return api.postData(BUCKET_ITEMS_ROUTE(brandId, bucketId), payload, params)
  },
  removeItemFromBucket (brandId, bucketId, mentions, params) {
    return api.post(BUCKET_ITEMS_ROUTE(brandId, bucketId) + '/delete', { mentions }, params)
  },
  reorderItemsInBucket (brandId, bucketId, mentions, params) {
    return api.post(BUCKET_ITEMS_ROUTE(brandId, bucketId) + '/update-order', { mentions }, params)
  }
}
