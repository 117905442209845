<template>
  <PageSection
    class="RSS"
    :page-title="$t('pages.settings.brand.rss.title')"
    :page-tooltip="$t('pages.settings.brand.rss.tooltip')"
  >
    <template #title-right>
      <VButton
        class="m-l-a is-black"
        :loading="isCreatingFeed"
        data-testid="createFeedTrigger"
        @click="createRssFeed"
      >
        {{ $t('pages.settings.brand.rss.create_button') }}
      </VButton>
    </template>

    <VSeparator fat />

    <div v-loading="fetchingFeeds" class="columns">
      <div class="column is-3">
        <FormField :label="$t('pages.settings.brand.rss.select_rss_label')">
          <BasicSelect
            v-model="currentFeedId"
            :values="feedsList"
            value-prop="id"
            label-prop="name"
            key-prop="id"
            popper-class="selectFeedDropdown"
            data-testid="selectFeed"
          />
        </FormField>

        <VSeparator class="m-v-l" />

        <div
          v-if="currentFeedId"
          v-loading="isUpdatingFeed"
        >
          <div class="p-a-s m-b-s">
            <EditableFieldExtended
              ref="name"
              v-model="form.name"
              :validator="$v.form.name"
              :has-cancel-button="false"
              :placeholder="$t('pages.settings.brand.rss.name_of_feed')"
              :attribute="$t('pages.settings.brand.rss.feed_name')"
              floating-label
              auto-stop-edit
              inline-placeholder
              data-testid="feedNameInput"
              @input="updateRssFeed"
            />
          </div>

          <FormField
            :label="$t('pages.settings.brand.rss.select_rss_sorting_type')"
            :validator="$v.form.sorting_type"
            class="m-b-m"
          >
            <BasicSelect
              v-model="form.sorting_type"
              :values="sortingOptions"
              label-prop="label"
              value-prop="value"
              key-prop="value"
              @input="updateSortingType"
            />
          </FormField>

          <div>
            <a
              :href="feedRssUrl"
              target="_blank"
              class="button is-plain is-black is-narrow"
              data-testid="previewFeedTrigger"
            >
              <span>{{ $t('pages.settings.brand.rss.view_rss_feed') }}</span>

              <span class="icon">
                <VIcon type="feed" />
              </span>
            </a>
          </div>

          <VSeparator class="m-v-l" />

          <VButton
            :loading="isDeletingFeed"
            class="is-plain is-primary is-narrow"
            data-testid="deleteFeedTrigger"
            @click="deleteRssFeed"
          >
            <span>{{ $t('pages.settings.brand.rss.delete_rss_feed') }}</span>

            <span class="icon">
              <VIcon type="bin" />
            </span>
          </VButton>
        </div>
      </div>

      <div class="column is-9">
        <transition name="fade">
          <LoadMore
            v-if="currentFeedId"
            ref="loadmore"
            :endpoint="feedsUrl"
          >
            <template slot="noItems">
              <div class="has-text-centered m-t-l" data-testid="noFeedItemsText">
                <i18n path="pages.settings.brand.rss.no_mentions">
                  <router-link
                    slot="link"
                    :to="{ name: 'streams.list' }"
                    class="has-text-decoration-underline has-text-primary"
                    data-testid="NoStreamsAddMoreTrigger"
                  >
                    {{ $t('general.streams') }}
                  </router-link>
                </i18n>
              </div>
            </template>

            <template #items="{ items: mentions, removeItem }">
              <draggable
                key="draggable"
                :list="mentions"
                :disabled="isDraggingDisabled"
                handle=".drag-handler"
                @change="reorderItems(mentions)"
              >
                <MentionItemBase
                  v-for="mention in mentions"
                  :key="mention.id"
                  :media-title-name="mention.media_title_name"
                  :content-title="mention.content_title"
                  :content-body-highlighted="mention.content_body_highlighted"
                  :content-body="mention.content_body"
                  :published-at="mention.published_at"
                  :countries="mention.countries"
                  :languages="mention.languages"
                  :presence="mention.presence"
                  :sentiment="mention.sentiment"
                  :type="mention.type"
                  :impressions="mention.impressions"
                  :visits-pr-month="mention.visits_pr_month"
                  :estimated-clicks="mention.estimated_clicks"
                  :image="mention.thumbnail"
                  :source="mention.source_link"
                  class="Mention-rss-item m-b-m"
                >
                  <template #header-actions>
                    <VButton
                      class="is-black is-plain is-narrow drag-handler is-drag-handler"
                      type="button"
                      data-testid="dragHandleTrigger"
                      :disabled="isDraggingDisabled"
                      :title="$t('pages.settings.brand.rss.drag_to_reorder')"
                    >
                      <div class="icon">
                        <VIcon type="grid" />
                      </div>
                    </VButton>

                    <VButton
                      class="is-primary is-plain is-narrow"
                      :loading="isRemovingItem === mention.id"
                      data-testid="removeItemFromFeed"
                      @click="removeMentionFromFeed(mention.id, removeItem)"
                    >
                      <div class="icon">
                        <VIcon type="bin" />
                      </div>
                    </VButton>
                  </template>
                </MentionItemBase>
              </draggable>
            </template>
          </LoadMore>

          <div v-else class="has-text-centered m-v-xl">
            <i18n path="pages.settings.brand.rss.no_feeds">
              <a
                slot="link"
                href="#"
                class="has-text-decoration-underline"
                data-testid="noRssFeedsCreateTrigger"
                @click.prevent="createRssFeed"
              >
                {{ $t('pages.settings.brand.rss.create_feed_trigger') }}
              </a>
            </i18n>
          </div>
        </transition>
      </div>
    </div>
  </PageSection>
</template>

<script>
import draggable from 'vuedraggable'
import { required } from 'vuelidate/lib/validators'

import MentionItemBase from '@hypefactors/shared/js/components/streams/MentionItemBase.vue'
import VButton from '@hypefactors/shared/js/components/core/VButton.vue'
import VIcon from '@hypefactors/shared/js/components/core/VIcon.vue'
import VSeparator from '@hypefactors/shared/js/components/core/VSeparator.vue'
import FormField from '@hypefactors/shared/js/components/forms/FormField.vue'
import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect.vue'

import { BUCKET_ITEMS_ROUTE, RSSBucketApiService } from '@/services/api/RSSBucketApiService.js'

import PageSection from '@/components/core/PageSection.vue'
import EditableFieldExtended from '@/components/forms/EditableFieldExtended.vue'
import LoadMore from '@/components/pagination/LoadMore.vue'

export default {
  components: {
    draggable,
    PageSection,
    VButton,
    VIcon,
    VSeparator,
    FormField,
    BasicSelect,
    MentionItemBase,
    EditableFieldExtended,
    LoadMore
  },

  data () {
    return {
      currentFeedId: null,
      feedsList: [],
      form: {
        name: '',
        sorting_type: null
      },
      fetchingFeeds: false,
      isDeletingFeed: false,
      isCreatingFeed: false,
      isUpdatingFeed: false,
      isRemovingItem: false
    }
  },

  validations: {
    form: {
      name: { required },
      sorting_type: { required }
    }
  },

  computed: {
    sortingOptions () {
      return ['manual', 'old_to_new', 'new_to_old'].map((option) => ({
        value: option,
        label: this.$t(`pages.settings.brand.rss.sort_options.${option}`)
      }))
    },

    feedsUrl () {
      return BUCKET_ITEMS_ROUTE(this.activeBrandId, this.currentFeedId)
    },

    feedRssUrl () {
      return this.$getUrl(`/rss/buckets/${this.currentFeedId}`, 'api')
    },

    isDraggingDisabled () {
      return this.form.sorting_type !== 'manual'
    }
  },

  watch: {
    activeBrandId: {
      handler: 'fetchFeeds',
      immediate: true
    },

    currentFeedId (id) {
      if (!id) return
      this.form = { ...this.feedsList.find(f => f.id === id) }
    }
  },

  methods: {
    async fetchFeeds (brandId) {
      this.fetchingFeeds = true

      this.feedsList = await RSSBucketApiService.fetchAll(brandId)

      this.selectFirstAvailableFeed()

      this.fetchingFeeds = false
    },

    async createRssFeed () {
      try {
        this.isCreatingFeed = true

        const { value } = await this.$prompt(
          this.$t('pages.settings.brand.rss.give_feed_name'),
          this.$t('pages.settings.brand.rss.create_feed')
        )

        const bucket = await RSSBucketApiService.create(this.activeBrandId, {
          name: value,
          sorting_type: 'manual'
        })

        this.feedsList.push(bucket)

        this.currentFeedId = bucket.id

        this.form = { ...bucket }
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isCreatingFeed = false
      }
    },

    async updateRssFeed () {
      try {
        await this.updateRssFeedQuery()
      } catch (err) {
        this.$displayRequestError(err)
      }
    },

    async updateRssFeedQuery () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.isUpdatingFeed = true

      try {
        const bucketResponse = await RSSBucketApiService.updateBucket(this.activeBrandId, this.currentFeedId, this.form)

        const index = this.feedsList.findIndex(f => f.id === this.currentFeedId)

        this.feedsList.splice(index, 1, bucketResponse)
      } finally {
        this.isUpdatingFeed = false
      }
    },

    async deleteRssFeed () {
      this.isDeletingFeed = true

      try {
        await this.$confirm(this.$t('pages.settings.brand.rss.confirm_deletion'))

        await RSSBucketApiService.deleteBucket(this.activeBrandId, this.currentFeedId)

        const index = this.feedsList.findIndex(f => f.id === this.currentFeedId)

        this.feedsList.splice(index, 1)

        this.selectFirstAvailableFeed()
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isDeletingFeed = false
      }
    },

    reorderItems (items) {
      RSSBucketApiService.reorderItemsInBucket(this.activeBrandId, this.currentFeedId, items.map(f => f.id))
        .catch((error) => {
          this.$displayRequestError(error)
        })
    },

    async removeMentionFromFeed (mentionId, removeItemFromList) {
      try {
        this.isRemovingItem = mentionId

        await this.$confirm(this.$t('pages.settings.brand.rss.confirm_mention_remove'))

        await RSSBucketApiService.removeItemFromBucket(this.activeBrandId, this.currentFeedId, [mentionId])

        removeItemFromList({ id: mentionId }, 'id')
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isRemovingItem = null
      }
    },

    selectFirstAvailableFeed () {
      const feed = (this.feedsList[0] || { id: null })

      this.currentFeedId = feed.id
    },

    async updateSortingType () {
      try {
        await this.$confirm(this.$t('pages.settings.brand.rss.confirm_sort_update'))

        await this.updateRssFeedQuery()

        this.$refs.loadmore.refresh()
      } catch (err) {
        this.$displayRequestError(err)
      }
    }
  }
}
</script>
